<script setup lang="ts">
import { useGoogleStore } from "../store/google";
import { Keyboard, Mousewheel, Navigation, A11y } from 'swiper/modules';
import a11yRawCSS from 'swiper/element/css/a11y?raw';
import navigationRawCSS from 'swiper/element/css/navigation?raw';
import keyboardRawCSS from 'swiper/element/css/keyboard?raw';
import mousewheelRawCSS from 'swiper/element/css/mousewheel?raw';
import { register } from 'swiper/element';

register();

const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
});

const swiperRef = ref(null);
const googleStore = useGoogleStore();
const { locale } = useI18n();
const modules = [Keyboard, Mousewheel, Navigation, A11y];

const swiperParams = {
    modules,
    slidesPerView: 1,
    spaceBetween: 32,
    breakpoints: {
      480: {
        spaceBetween: 40,
      },
      640: {
        spaceBetween: 48,
      },
      768: {
        spaceBetween: 56,
      },
    },
    injectStyles: [
      `
      ${a11yRawCSS}

      ${navigationRawCSS}

      ${keyboardRawCSS}

      ${mousewheelRawCSS}
      `,
    ]
};

onMounted(async () => {
  if (!googleStore.reviews) {
    await googleStore.fetchReviews();
  }

  if (swiperRef.value) {
    Object.assign(swiperRef.value, swiperParams);
    swiperRef.value.initialize();
  }
});

watch(
  () => locale.value,
  async () => {
    await googleStore.fetchReviews();
  }
);

onServerPrefetch(async () => {
  await googleStore.fetchReviews();
});
</script>

<template>
  <BaseContentSection v-if="googleStore.reviews?.length" v-editable="blok" background-color="light" class="text-center">
    <div class="mb-2">
      {{ googleStore.rating }} {{ blok.starsDescription }}
    </div>
    <BaseRating
      class="mb-2 text-3xl"
      :rating="googleStore.rating"
    ></BaseRating>
    <BaseHeadlineAndText
      :headline="blok.headline"
      :text="blok.description"
      class="mb-10"
    ></BaseHeadlineAndText>

    <BaseNote
      class="xs:max-w-screen-xs sm:max-w-screen-sm mx-auto px-0 sm:px-0 md:px-0 lg:px-0"
    >
      <swiper-container
        init="false"
        ref="swiperRef"
        a11y="true"
        navigation="true"
        keyboard="true"
        grabCursor="true"
      >
          <template
          v-for="review of googleStore.reviews"
          :key="review.authorAttribution.displayName"
          >
          <swiper-slide v-if="review.text?.text" class="self-center">
            <strong class="block mb-2">{{ review.authorAttribution?.displayName }}</strong>
            <blockquote class="mb-4 italic">"{{ review.text?.text }}"</blockquote>
            <BaseRating class="text-lg" :rating="review.rating"></BaseRating>
          </swiper-slide>
        </template>
      </swiper-container>
    </BaseNote>
  </BaseContentSection>
</template>

<style lang="scss" scoped>
swiper-container::part(container){
  --review-navigation-width: 32px;

  padding: 0 var(--review-navigation-width) !important;
  box-sizing: border-box;

  @screen xs {
    --review-navigation-width: 40px;
  }

  @screen sm {
    --review-navigation-width: 48px;
  }

  @screen md {
    --review-navigation-width: 56px;
  }
}

swiper-container::part(button-prev),
swiper-container::part(button-next) {
  @apply bg-transparent text-font border-transparent;

  width: calc(var(--review-navigation-width) / 2);
  height: calc(var(--review-navigation-width) / 2);
  border: none;
}
</style>
