import { defineStore } from 'pinia';

declare type IGoogleResponse = {
    rating: number;
    reviews: Array<Array<unknown>>;
};

export const useGoogleStore = defineStore('google', () => {
    const app = useNuxtApp();
    const reviews = ref<Array<Array<unknown>> | null>(null);
    const rating = ref<number>(0);

    const fetchReviews = async () => {
        try {
            const locale = app.$i18n.locale as unknown as { value: string };
            const response: IGoogleResponse = await $fetch('/api/google-reviews', {
                method: 'get',
                query: {
                    languageCode: locale.value
                }
            });

            if (!response) {
                console.error(response);
            }

            reviews.value = response.reviews;
            rating.value = response.rating;
        } catch (errorResponse) {
            console.error('ERROR', errorResponse)
            reviews.value = null;
        }
    }

    return {
        reviews,
        rating,
        fetchReviews
    };
});

export default useGoogleStore;